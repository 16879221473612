import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://castelanismos-non-api.herokuapp.com/api/';

class UserService {
  getMaterialsContent() {
    return axios.get(API_URL + 'language', { headers: authHeader() });
  }

  getWord() {
    return axios.get(API_URL + 'language/head', { headers: authHeader() });
  }

  guessWord(guess) {
    return axios.post(API_URL + 'language/guess', {guess},{ headers: authHeader() });
  }


}

export default new UserService();