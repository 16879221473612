import axios from 'axios';

const API_URL = 'https://castelanismos-non-api.herokuapp.com/api/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'auth/token', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.authToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return user;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'user', {
      username: user.username,
      name: user.name,
      password: user.password
    });
  }
}

export default new AuthService();