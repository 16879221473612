import UserService from '../services/user.service';

const initialState = {
    language: {
        head: null,
        id: null,
        name: null,
        total_score: null,
        user_id: null
    },
    words: []
}

export const user = {
    namespaced: true,
    state: initialState,
    actions: {
        getMaterials({ commit }) {
            return UserService.getMaterialsContent().then(
                response => {
                    commit('setMaterials', response.data);
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        setMaterials(state, materials) {
            state.language = materials.language
            state.words = materials.words
        },
    }
};