import { createRouter, createWebHistory } from '@ionic/vue-router';


const routes = [
    {
        path: '/',
        name: 'auth',
        component: () => import('@/views/Auth.vue'),
    },
    {
        path: '/identificarse',
        name: 'login',
        component: () => import('@/views/Login.vue'),
    },
    {
        path: '/rexistrarse',
        name: 'register',
        component: () => import('@/views/Register.vue'),
    },
    {
        path: '/definicions/:word',
        name: 'definition',
        component: () => import('@/views/Definition.vue'),
        props: true
    },
    {
        path: '/materiais',
        name: 'materials',
        component: () => import('@/views/Materials.vue'),
    },
    {
        path: '/practicar',
        name: 'practice',
        component: () => import('@/views/Practice.vue'),
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/identificarse', '/rexistrarse', '/'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    next('/identificarse');
  } else {
    next();
  }
});

export default router
